import { DocumentNode, gql } from "@apollo/client";
import clsx from "clsx";
import {
  CSSProperties,
  NamedExoticComponent,
  ReactNode,
  forwardRef,
  memo,
} from "react";
import {
  IoCheckmarkCircle,
  RefreshDoubleCircle,
  Scheduler,
  Uncheck,
} from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { Time } from "@/components/Time";
import { EventStatusOverlay } from "@/containers/events/EventStatusOverlay";

export type DateDisplayProps = { date: string | null };

export const DateDisplay = (props: DateDisplayProps) => {
  if (!props.date) return null;
  return (
    <div>
      le <Time date={props.date} format="LLL" />
    </div>
  );
};

export type AuthorDisplayProps = { user: { fullName: string } | null };

export const AuthorDisplay = (props: AuthorDisplayProps) => {
  if (!props.user?.fullName) return null;
  return <div>par {props.user.fullName}</div>;
};

AuthorDisplay.fragments = {
  user: gql`
    fragment AuthorDisplay_user on User {
      fullName
    }
  `,
};

export type Event = {
  actor: {
    fullName: string;
  } | null;
  date: string;
  status: string;
};

type TooltipContentProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
};

const TooltipContent = (props: TooltipContentProps) => {
  return (
    <>
      <div className="font-semibold">{props.title}</div>
      {props.children}
    </>
  );
};

type ArticlePublishStatus =
  | "published"
  | "published_as_new_content"
  | "unpublished"
  | "scheduled"
  | "pending";

const getArticlePublishStatus = (article: {
  lastPublishedHidden: boolean;
  published: boolean;
  publishInfos: {
    lastPublishedAsNewContentIn: Event | null;
  };
  scheduled: boolean;
}): ArticlePublishStatus => {
  if (article.lastPublishedHidden) return "unpublished";
  if (article.publishInfos.lastPublishedAsNewContentIn)
    return "published_as_new_content";
  if (article.published) return "published";
  if (article.scheduled) return "scheduled";
  return "pending";
};

type Icon = React.FC<{ size: number }>;

const statusConfigs: Record<
  ArticlePublishStatus,
  { icon: Icon; label: string }
> = {
  published: {
    icon: ({ size }) => (
      <IoCheckmarkCircle className="text-success-bg-strong" size={size} />
    ),
    label: "Publié",
  },
  published_as_new_content: {
    icon: ({ size }) => (
      <RefreshDoubleCircle
        className="text-success-bg-strong"
        width={size}
        height={size}
      />
    ),
    label: "Publié comme nouveau contenu",
  },
  unpublished: {
    icon: ({ size }) => (
      <Uncheck width={size} height={size} className="text-danger-bg-strong" />
    ),
    label: "Dépublié",
  },
  pending: {
    icon: () => <div className="h-2 w-2 rounded-full bg-grey-bg-strong" />,
    label: "Brouillon",
  },
  scheduled: {
    icon: ({ size }) => <Scheduler width={size - 2} height={size - 2} />,
    label: "Programmé",
  },
};

type ArticlePublishStatusIconProps = {
  status: ArticlePublishStatus;
  size?: number;
} & React.HTMLAttributes<HTMLDivElement>;

const ArticlePublishStatusIcon = memo(
  forwardRef<HTMLDivElement, ArticlePublishStatusIconProps>(
    ({ status, size = 22, ...props }, ref) => {
      const config = statusConfigs[status];
      return (
        <div
          ref={ref}
          {...props}
          className={clsx("flex items-center justify-center", props.className)}
          style={{ width: size, height: size, ...props.style }}
        >
          <config.icon size={size} />
        </div>
      );
    },
  ),
);

const getLabelForStatus = (status: ArticlePublishStatus) => {
  return statusConfigs[status].label;
};

export type ArticlePublishStatusProps = {
  article: {
    lastPublishedHidden: boolean;
    published: boolean;
    scheduled: boolean;
    scheduledAt: string | null;
    scheduledBy: {
      id: string;
      fullName: string;
    } | null;
    publishInfos: {
      lastPublishedIn: Event | null;
      lastAttemptedPublishIn: Event | null;
      lastPublishedAsNewContentIn: Event | null;
    };
  };
  size?: number;
};

const ArticlePendingStatus = memo<{ size?: number }>(({ size }) => {
  return (
    <Tooltip tooltip={<TooltipContent title={getLabelForStatus("pending")} />}>
      <ArticlePublishStatusIcon status="pending" size={size} />
    </Tooltip>
  );
});

type ArticleScheduledStatusProps = {
  scheduledAt: string | null;
  scheduledBy: {
    fullName: string;
  } | null;
  size?: number;
};

const ArticleScheduledStatus = memo((props: ArticleScheduledStatusProps) => {
  return (
    <Tooltip
      tooltip={
        props.scheduledAt ? (
          <TooltipContent
            title={
              <>
                {getLabelForStatus("scheduled")} le{" "}
                <Time date={props.scheduledAt} format="LLL" />
              </>
            }
          >
            <AuthorDisplay user={props.scheduledBy} />
          </TooltipContent>
        ) : null
      }
    >
      <ArticlePublishStatusIcon status="scheduled" size={props.size} />
    </Tooltip>
  );
});

type ArticlePublishedStatusProps = {
  status: "published" | "published_as_new_content" | "unpublished";
  publishInfos: ArticlePublishStatusProps["article"]["publishInfos"];
  style?: CSSProperties;
  children?: ReactNode;
  size?: number;
};

export const ArticlePublishedStatus = memo((props) => {
  const {
    lastAttemptedPublishIn,
    lastPublishedAsNewContentIn,
    lastPublishedIn,
  } = props.publishInfos;

  const event = lastPublishedAsNewContentIn ?? lastPublishedIn;

  return (
    <EventStatusOverlay
      event={
        lastAttemptedPublishIn?.status !== "success"
          ? lastAttemptedPublishIn
          : null
      }
      scale={0.8}
    >
      <Tooltip
        tooltip={
          <TooltipContent title={getLabelForStatus(props.status)}>
            <DateDisplay date={event?.date ?? null} />
            <AuthorDisplay user={event?.actor ?? null} />
          </TooltipContent>
        }
      >
        <div className="flex items-center gap-1">
          <ArticlePublishStatusIcon
            status={props.status}
            style={props.style}
            size={props.size}
          />
          {props.children}
        </div>
      </Tooltip>
    </EventStatusOverlay>
  );
}) as NamedExoticComponent<ArticlePublishedStatusProps> & {
  fragments: { article: DocumentNode };
};

ArticlePublishedStatus.fragments = {
  article: gql`
    fragment ArticlePublishedStatus_article on Article {
      publishInfos {
        lastPublishedIn {
          id
          date
          actor {
            id
            ...AuthorDisplay_user
          }
        }
        lastPublishedAsNewContentIn {
          id
          date
          actor {
            id
            ...AuthorDisplay_user
          }
        }
        lastAttemptedPublishIn {
          id
          status
          ...EventStatusOverlay_event
        }
      }
    }
    ${AuthorDisplay.fragments.user}
    ${EventStatusOverlay.fragments.event}
  `,
};

export type ArticlePublishStatusLabelProps = {
  article: {
    lastPublishedHidden: boolean;
    published: boolean;
    scheduled: boolean;
    publishInfos: {
      lastPublishedAsNewContentIn: Event | null;
    };
  };
};

export const ArticlePublishStatusLabel = (
  props: ArticlePublishStatusLabelProps,
) => {
  const status = getArticlePublishStatus(props.article);
  const config = statusConfigs[status];
  return config.label as unknown as JSX.Element;
};

ArticlePublishStatusLabel.fragments = {
  article: gql`
    fragment ArticlePublishStatusLabel_article on Article {
      published
      scheduled
      lastPublishedHidden
    }
  `,
};

export const ArticlePublishStatus = ({
  article,
  size,
}: ArticlePublishStatusProps) => {
  const status = getArticlePublishStatus(article);

  switch (status) {
    case "published":
    case "published_as_new_content":
    case "unpublished":
      return (
        <ArticlePublishedStatus
          status={status}
          publishInfos={article.publishInfos}
          size={size}
        />
      );

    case "scheduled":
      return (
        <ArticleScheduledStatus
          scheduledAt={article.scheduledAt}
          scheduledBy={article.scheduledBy}
          size={size}
        />
      );

    case "pending":
      return <ArticlePendingStatus size={size} />;
  }
};

ArticlePublishStatus.fragments = {
  article: gql`
    fragment ArticlePublishStatus_article on Article {
      id
      published
      scheduled
      scheduledAt
      scheduledBy {
        id
        fullName
      }
      lastPublishedHidden
      ...ArticlePublishedStatus_article
    }
    ${ArticlePublishedStatus.fragments.article}
    ${AuthorDisplay.fragments.user}
    ${EventStatusOverlay.fragments.event}
  `,
};
