import { DocumentNode, gql } from "@apollo/client";
import clsx from "clsx";

import { Image, ImageFluidFragment } from "@/components/Image";

import { useSafeQuery } from "../Apollo";

export type ArticleFeaturedMediaProps =
  React.HTMLAttributes<HTMLImageElement> & {
    article: {
      featuredMedias: {
        nodes: {
          defaultCropRegion: {
            top: number;
            left: number;
            width: number;
            height: number;
          };
          image: {
            id: number;
          };
        }[];
      };
    };
  };
export type ArticleFeaturedMediaFragments = {
  article: DocumentNode;
};

export const ArticleFeaturedMedia: React.FC<ArticleFeaturedMediaProps> & {
  fragments: ArticleFeaturedMediaFragments;
} = ({ article, ...props }) => {
  const [featuredMedia] = article.featuredMedias.nodes;
  if (!featuredMedia) return null;
  return (
    <CroppedImage
      imageId={featuredMedia.image.id}
      region={featuredMedia.defaultCropRegion}
      {...props}
    />
  );
};

ArticleFeaturedMedia.fragments = {
  article: gql`
    fragment ArticleFeaturedMedia_article on Article {
      featuredMedias: medias(limit: 1, where: { featured: true }) {
        nodes {
          defaultCropRegion {
            top
            left
            width
            height
          }
          image: media {
            id
          }
        }
      }
    }
  `,
};

type Region =
  ArticleFeaturedMediaProps["article"]["featuredMedias"]["nodes"][number]["defaultCropRegion"];

type CroppedImageProps = {
  imageId: number;
  region: Region;
};

const CroppedImage: React.FC<
  React.HTMLAttributes<HTMLImageElement> & CroppedImageProps
> = ({ imageId, region, ...props }) => {
  const { data } = useSafeQuery<
    CroppedImageQueryResult,
    CroppedImageQueryVariables
  >(CroppedImageQuery, {
    variables: {
      region: {
        top: region.top,
        left: region.left,
        width: region.width,
        height: region.height,
      },
      id: imageId,
    },
  });

  return (
    <Image
      {...data?.image?.cropped}
      {...props}
      className={clsx("aspect-[3/2] bg-black object-contain", props.className)}
    />
  );
};

const CroppedImageQuery = gql`
  query ArticleFeaturedMedia_image($id: Int!, $region: ImageRegionInput!) {
    image(id: $id) {
      id
      cropped: fluid(region: $region, maxHeight: 76) {
        ...ImageFluidFragment
      }
    }
  }

  ${ImageFluidFragment}
`;

type CroppedImageQueryResult = {
  image: {
    id: number;
    // TODO: Type properly
    cropped: any;
  };
};
type CroppedImageQueryVariables = {
  id: number;
  region: Region;
};
