import { gql, useSubscription } from "@apollo/client";
import arrayMutators from "final-form-arrays";
import moment from "moment";
import "moment/locale/fr";
import { useMemo } from "react";
import { PageLoader } from "swash/Loader";
import { PanelSection } from "swash/Panel";
import { Tooltip } from "swash/Tooltip";
import { FormLabel } from "swash/form/FormLabel";

import {
  ArticleAuditTrailsProvider,
  AuditTrailFragment,
  BaseArticleAuditTrailFragment,
  useArticleAuditTrailTooltip,
} from "@/components/ArticleAuditTrail";
import { EmbargoTime } from "@/components/EmbargoTime";
import {
  EditorialLevelField,
  editorialLevels,
} from "@/components/controls/EditorialLevelSelect";
import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormSubmittingPrompt } from "@/components/forms/FormPrompt";
import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { PanelHeaderTeleporter } from "@/components/teleporters/PanelHeader";
import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { HasPermission, useHasPermission } from "@/containers/User";
import {
  BooleanField,
  EnumField,
  useNodesToEnumArray,
} from "@/containers/admin/CRUD";
import {
  PublicationDateField,
  formatPublicationDate,
  parsePublicationDate,
} from "@/containers/article/fields/PublicationDate";
import { formatValues, parseValues } from "@/services/forms/utils";

import { ExposuresField } from "./exposures-section/ExposuresField";

const ArticleExposureFormAuditTrailFragment = gql`
  fragment AuditTrail_ArticleExposureForm_article on Article {
    editorialLevel_auditTrail: lastAuditTrail(
      where: { fields: { eq: "editorialLevel" } }
    ) {
      id
      ...Article_auditTrail
    }
    publicationDate_auditTrail: lastAuditTrail(
      where: { fields: { in: ["planning.date", "isEmbargo", "isUrgent"] } }
    ) {
      id
      ...Article_auditTrail
    }
    ...BaseAuditTrail_article
  }
  ${AuditTrailFragment}
  ${BaseArticleAuditTrailFragment}
`;

const ArticleFragment = gql`
  fragment ArticleExposure_article on Article {
    initialFirstPublished
    pricingTierId
    editorialLevel
    scheduled
    scheduledAt
    planning {
      range
      date
    }
    isEmbargo
    isUrgent
    publicationForbidden
    legacyCustomFields {
      allowComments
      forceShowInThread
    }
    editorialType {
      id
      canBeNodeOnly
    }
    articleExposures {
      nodes {
        gid
        ...ExposuresField_articleExposures
      }
    }
    ...ExposuresField_article
    ...AuditTrail_ArticleExposureForm_article
  }

  ${ExposuresField.fragments.article}
  ${ExposuresField.fragments.articleExposures}
  ${ArticleExposureFormAuditTrailFragment}
`;

const ArticleQuery = gql`
  query ArticleExposure_article($id: Int!) {
    article(id: $id) {
      id
      ...ArticleExposure_article
    }
  }
  ${ArticleFragment}
`;

const UpdateArticleMutation = gql`
  mutation ArticleExposure_updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      id
      ...ArticleExposure_article
    }
  }
  ${ArticleFragment}
`;

const UpdateArticleSubscription = gql`
  subscription ArticleExposure_articleUpdated($id: Int!) {
    articleUpdated(where: { id: { eq: $id } }) {
      id
      ...ArticleExposure_article
    }
  }
  ${ArticleFragment}
`;

const PricingTiersQuery = gql`
  query ArticleExposurePricingTiers {
    pricingTiers {
      nodes {
        id
        value
        label
        acronym
        color
        rank
      }
    }
  }
`;

function EditorialLevelSelectField() {
  const hasPermission = useHasPermission("editorialLevel:edit");
  const tooltip =
    useArticleAuditTrailTooltip("editorialLevel") ?? "Éditer l’indice";

  return (
    <Tooltip tooltip={tooltip} placement="left">
      <div className="flex w-full flex-row items-center justify-between">
        <FormLabel>Indice</FormLabel>
        <EditorialLevelField
          disabled={!hasPermission}
          multi={false}
          clearable={false}
          format={(v) =>
            editorialLevels.find((item) => item.value === v) || null
          }
          parse={(v) => v?.value ?? null}
        />
      </div>
    </Tooltip>
  );
}

function PricingTierSwitchField({ pricingTier, disabled }) {
  return (
    <BooleanField
      name="pricingTierId"
      label={pricingTier.label}
      format={(value) => Boolean(value)}
      parse={(checked) => (checked ? pricingTier.id : null)}
      tint={pricingTier.color}
      disabled={disabled}
      appearance="switch"
    />
  );
}

function PricingTierEnumField({ pricingTiers, disabled }) {
  const freePricingTier = { label: "Gratuit", id: 0 };
  const enumValues = useNodesToEnumArray([freePricingTier, ...pricingTiers]);

  return (
    <EnumField
      name="pricingTierId"
      label="Droit d'accès"
      enum={enumValues}
      disabled={disabled}
      parse={(value) => (value?.id === 0 ? null : value.id)}
      format={(value) =>
        !value
          ? { value: 0, label: "Gratuit" }
          : enumValues.find((item) => item.value === value)
      }
    />
  );
}

function PricingTierField() {
  const { data } = useSafeQuery(PricingTiersQuery);
  const hasPermission = useHasPermission("article:metas:pricingTier");
  const pricingTiers = data?.pricingTiers?.nodes;

  if (!pricingTiers || !pricingTiers.length) return null;

  const field =
    pricingTiers.length > 1 ? (
      <PricingTierEnumField
        pricingTiers={pricingTiers}
        disabled={!hasPermission}
      />
    ) : (
      <PricingTierSwitchField
        pricingTier={pricingTiers[0]}
        disabled={!hasPermission}
      />
    );

  return hasPermission ? (
    <div>{field}</div>
  ) : (
    <Tooltip
      tooltip="Vous n’avez pas la permission nécessaire pour agir sur ce champ"
      placement="left"
    >
      <div>{field}</div>
    </Tooltip>
  );
}

function ShowInThreadSwitchField({ disabled }) {
  return (
    <BooleanField
      name="forceShowInThread"
      label="Afficher dans les fleuves"
      disabled={disabled}
      appearance="switch"
    />
  );
}

function PublicationForbiddenSwitchField({ disabled }) {
  return (
    <BooleanField
      name="publicationForbidden"
      label="Bloquer la publication"
      disabled={disabled}
      appearance="switch"
      tint="danger"
    />
  );
}

function AllowCommentsSwitchField({ disabled }) {
  const hasPermission = useHasPermission("allowComments:edit");

  return (
    <BooleanField
      name="disableComments"
      label="Désactiver les commentaires"
      disabled={disabled || !hasPermission}
      appearance="switch"
    />
  );
}

function InnerPublicationInfos({ date, label, embargo }) {
  const formattedTime = moment(date).format("HH:mm");
  return (
    <div>
      <div className="mb-2 font-accent font-bold">{label}</div>
      <div data-test-hidden>
        le {moment(date).format("DD/MM/YYYY")} à{" "}
        {embargo ? <EmbargoTime time={formattedTime} /> : formattedTime}
      </div>
    </div>
  );
}

function PublicationInfos({ initialFirstPublished, scheduledAt, embargo }) {
  if (initialFirstPublished) {
    return (
      <InnerPublicationInfos
        label="Article publié sur le numérique"
        date={initialFirstPublished}
      />
    );
  }
  return (
    <InnerPublicationInfos
      label="Article programmé"
      date={scheduledAt}
      embargo={embargo}
    />
  );
}

const fields = {
  pricingTierId: {
    format: ({ article }) => article.pricingTierId,
    parse: ({ pricingTierId }) => ({ pricingTierId }),
  },
  editorialLevel: {
    format: ({ article }) => article.editorialLevel,
    parse: ({ editorialLevel }) => ({ editorialLevel }),
  },
  publicationDate: {
    format: ({ article }) => formatPublicationDate(article).publicationDate,
    parse: (values) => parsePublicationDate(values),
  },
  publicationForbidden: {
    format: ({ article }) => article.publicationForbidden,
    parse: ({ publicationForbidden }) => ({
      publicationForbidden,
    }),
  },
  disableComments: {
    format: ({ article }) =>
      !(article.legacyCustomFields.allowComments ?? true),
    parse: (values) => ({
      legacyCustomFields: {
        allowComments: !values.disableComments,
      },
    }),
  },
  forceShowInThread: {
    format: ({ article }) =>
      article.legacyCustomFields.forceShowInThread ?? false,
    parse: (values) => ({
      legacyCustomFields: { forceShowInThread: values.forceShowInThread },
    }),
  },
  exposureIds: {
    format: ({ articleExposures }) =>
      articleExposures.map((exposure) => exposure.exposureId),
    parse: ({ exposureIds }) => ({ exposureIds }),
  },
  articleExposures: {
    format: ({ articleExposures }) => articleExposures,
    parse: ({ articleExposures }) => {
      return {
        articleExposures: articleExposures.map(
          ({
            correlatedPlannedDateToArticle,
            exposureId,
            planning,
            publication,
            suggested,
          }) => ({
            correlatedPlannedDateToArticle,
            exposureId,
            planning: { date: planning?.date, range: planning?.range },
            publicationId: publication?.id ?? null,
            suggested,
          }),
        ),
      };
    },
  },
};

export function ArticleExposureForm({ articleId }) {
  const { features } = useRemoteConfig();
  const showCommentsCheckbox = features.includes("showCommentsCheckbox");

  const { data } = useSafeQuery(ArticleQuery, {
    variables: { id: articleId },
    fetchPolicy: "cache-and-network",
  });

  useSubscription(UpdateArticleSubscription, {
    variables: { id: articleId },
  });

  const article = data?.article ?? null;

  const articleExposures = useMemo(() => {
    if (!article) return [];
    return article.articleExposures.nodes.filter(
      (articleExposure) =>
        articleExposure.fulfilled ||
        articleExposure.suggested ||
        articleExposure.selected,
    );
  }, [article]);

  const initialValues = useMemo(() => {
    if (!article) return {};

    return formatValues(fields, { article, articleExposures });
  }, [article, articleExposures]);

  const [updateArticle] = useSafeMutation(UpdateArticleMutation);

  if (!article) return <PageLoader />;
  return (
    <ArticleAuditTrailsProvider article={article}>
      <Form
        as="div"
        collaborative
        initialValues={initialValues}
        mutators={arrayMutators}
        onSubmit={async (values, form, { dirtyFields = {} }) => {
          const variables = parseValues(
            fields,
            Object.keys(dirtyFields),
            values,
          );

          if (!Object.keys(variables).length) return;

          await updateArticle({
            variables: {
              input: {
                id: article.id,
                ...variables,
              },
            },
          });
        }}
      >
        <FormAutoSubmit />
        <FormSubmittingPrompt message="Les données de ce panneau ne sont pas enregistrées. Êtes-vous sûr de vouloir quitter l’onglet ?" />
        <PanelHeaderTeleporter.Source>
          <FormSavingIndicator />
        </PanelHeaderTeleporter.Source>
        <PanelSection className="flex flex-col gap-4">
          <PricingTierField />
          {article.initialFirstPublished || article.scheduled ? (
            <PublicationInfos
              embargo={article.isEmbargo}
              initialFirstPublished={article.initialFirstPublished}
              scheduledAt={article.scheduledAt}
            />
          ) : (
            <>
              <PublicationDateField modal />
            </>
          )}
          <HasPermission
            permission={["editorialLevel:view", "editorialLevel:edit"]}
            method="some"
          >
            <EditorialLevelSelectField />
          </HasPermission>
          <HasPermission
            permission={["exposures:view", "exposures:edit"]}
            method="some"
          >
            <ExposuresField
              article={article}
              articleExposures={articleExposures}
            />
          </HasPermission>
        </PanelSection>

        <PanelSection className="flex flex-col gap-4">
          {article.editorialType.canBeNodeOnly && <ShowInThreadSwitchField />}
          {showCommentsCheckbox && <AllowCommentsSwitchField />}
          <PublicationForbiddenSwitchField
            disabled={article.scheduled && !article.initialFirstPublished}
          />
        </PanelSection>
      </Form>
    </ArticleAuditTrailsProvider>
  );
}
