import { cloneElement, useEffect, useRef, useState } from "react";
import { Button } from "swash/Button";
import { IoCheckmark, IoClose, IoLockClosed } from "swash/Icon";
import { HandHello } from "swash/icons";
import { useTransition } from "swash/motion/Transition";

import { Banner, BannerBody, BannerIcon } from "@/components/Banner";

import { useUser } from "../User";
import type { Locker } from "./LockApi";
import { LockState } from "./LockState";
import { LockerName } from "./LockerName";

const TransitionGroup: React.FC<{ children: React.ReactElement | null }> = ({
  children: childrenProp,
}) => {
  const visible = Boolean(childrenProp);
  const transition = useTransition(visible);
  const latestChildren = useRef<React.ReactElement>();
  latestChildren.current = childrenProp || latestChildren.current;
  return transition.hidden || !latestChildren.current
    ? null
    : cloneElement(latestChildren.current, { ...transition, animated: true });
};

const DISMISS_DELAY_MS = 4000;

type Notification = {
  type: "locked" | "lock-forced" | "request-accepted" | "request-rejected";
  user: Locker["user"];
};

export const LockBanner: React.FC<LockState> = ({ api, status, ...props }) => {
  const { listenRequestCompletion, listenLockForced } = api;
  const request = api.info?.request ?? null;
  const locker = api.info?.locker ?? null;

  const user = useUser();
  const [notification, setNotification] = useState<Notification | null>(null);

  // Listen for lock forced
  useEffect(
    () =>
      listenLockForced((locker) => {
        setNotification({ type: "lock-forced", user: locker.user });
      }),
    [listenLockForced],
  );

  // Listen for lock request completion
  useEffect(
    () =>
      listenRequestCompletion((completion) => {
        switch (completion.state) {
          case "accepted": {
            if (completion.locker.user.id === user.id) {
              setNotification({
                type: "locked",
                user: completion.applicant.user,
              });
              return;
            }
            if (completion.applicant.user.id === user.id) {
              setNotification({
                type: "request-accepted",
                user: completion.locker.user,
              });
              return;
            }
            return;
          }
          case "rejected": {
            if (completion.applicant.user.id === user.id) {
              setNotification({
                type: "request-rejected",
                user: completion.locker.user,
              });
              return;
            }
            return;
          }
          default:
            setNotification(null);
        }
      }),
    [user, listenRequestCompletion],
  );

  useEffect(() => {
    // If a new request is coming, then we remove notification
    if (request) {
      setNotification(null);
    }
  }, [request]);

  // Expire notification
  useEffect(() => {
    if (!notification) return undefined;
    const timeoutId = setTimeout(() => {
      setNotification(null);
    }, DISMISS_DELAY_MS);
    return () => clearTimeout(timeoutId);
  }, [notification]);

  function render(): React.ReactElement | null {
    if (notification) {
      switch (notification.type) {
        case "lock-forced":
          return (
            <Banner variant="danger" {...props}>
              <BannerBody>
                <BannerIcon>
                  <IoLockClosed />
                </BannerIcon>
                <LockerName user={notification.user} /> a pris le verrou de
                force
              </BannerBody>
            </Banner>
          );
        case "locked":
          return (
            <Banner variant="success" {...props}>
              <BannerBody>
                <BannerIcon>
                  <IoLockClosed />
                </BannerIcon>
                <LockerName user={notification.user} /> a obtenu le verrou
              </BannerBody>
            </Banner>
          );
        case "request-accepted":
          return (
            <Banner variant="success" {...props}>
              <BannerBody>
                <BannerIcon>
                  <IoCheckmark />
                </BannerIcon>
                <LockerName user={notification.user} /> a accepté votre demande
              </BannerBody>
            </Banner>
          );
        case "request-rejected":
          return (
            <Banner variant="danger" {...props}>
              <BannerBody>
                <BannerIcon>
                  <IoClose />
                </BannerIcon>
                <LockerName user={notification.user} /> a refusé votre demande
              </BannerBody>
            </Banner>
          );
        default:
          return null;
      }
    }

    if (!request) return null;

    switch (status) {
      case "locked-by-me":
      case "locked-by-me-elsewhere":
        return (
          <Banner {...props}>
            <BannerBody className="gap-2">
              <div className="shrink-0">
                <BannerIcon>
                  <HandHello />
                </BannerIcon>
                <LockerName user={request.applicant.user} /> a demandé à prendre
                le verrou
              </div>
              <div className="flex gap-2">
                <div>
                  <Button
                    type="button"
                    scale="sm"
                    onClick={() => {
                      api.acceptLockRequest();
                    }}
                  >
                    Accepter
                  </Button>
                </div>
                <div>
                  <Button
                    type="button"
                    variant="secondary"
                    appearance="text"
                    scale="sm"
                    onClick={() => {
                      api.rejectLockRequest();
                    }}
                  >
                    Refuser
                  </Button>
                </div>
              </div>
            </BannerBody>
          </Banner>
        );
      case "locked-by-someone-else": {
        if (request.applicant.user.id !== user.id) return null;

        return (
          <Banner {...props}>
            <BannerBody className="gap-2">
              <div className="shrink-0">
                <BannerIcon>
                  <HandHello />
                </BannerIcon>
                En attente d’une réponse de <LockerName user={locker!.user} />
              </div>
              <div className="flex gap-2">
                <Button
                  variant="secondary"
                  appearance="text"
                  type="button"
                  scale="sm"
                  onClick={() => {
                    api.cancelLockRequest();
                  }}
                >
                  Annuler
                </Button>
              </div>
            </BannerBody>
          </Banner>
        );
      }
      default:
        return null;
    }
  }

  return <TransitionGroup>{render()}</TransitionGroup>;
};
