import {
  type FetchResult,
  gql,
  useApolloClient,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useSafeQuery } from "@/containers/Apollo";

import { APP_UUID } from "../AppUuid";
import { useRevalidate } from "../Revalidation";
import { useUser } from "../User";
import {
  useEmitRequestCompletion,
  useListenRequestCompletion,
  useTrackLockResource,
} from "./LockTracker";
import { useLockUpdating } from "./LockUpdatingProvider";

export type Locker = {
  appUuid: string;
  user: {
    id: number;
    firstNameInitials: string | null;
    lastName: string | null;
  };
};

const LockerFragment = gql`
  fragment LockerFragment on ModernLocker {
    appUuid
    user {
      id
      firstNameInitials
      lastName
    }
  }
`;

type LockInfo = {
  id: string;
  locker: Locker | null;
  request: { applicant: Locker } | null;
};

const LockFragment = gql`
  fragment LockFragment on ModernLock {
    id
    locker {
      ...LockerFragment
    }
    request {
      applicant {
        ...LockerFragment
      }
    }
  }

  ${LockerFragment}
`;

export type LockRequestCompletion = {
  state: "accepted" | "rejected" | "cancelled";
  lock: LockInfo;
  locker: Locker;
  applicant: Locker;
};

const LockRequestCompletionFragment = gql`
  fragment LockRequestCompletionFragment on LockRequestCompletion {
    state
    lock {
      ...LockFragment
    }
    locker {
      ...LockerFragment
    }
    applicant {
      ...LockerFragment
    }
  }

  ${LockFragment}
  ${LockerFragment}
`;

const LockMutation = gql`
  mutation LockMutation($id: String!) {
    lock(input: { id: $id }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const UnlockMutation = gql`
  mutation UnlockMutation($id: String!) {
    unlock(input: { id: $id }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const RequestLockMutation = gql`
  mutation RequestLockMutation($id: String!) {
    modernRequestLock(input: { id: $id }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

type LockResult = {
  lock: LockInfo;
};

const LockQuery = gql`
  query LockApiLockQuery($id: String!) {
    lock(id: $id) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const LocksQuery = gql`
  query LockApiLocksQuery($ids: [String!]!) {
    modernLocks(where: { id: { in: $ids } }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const LockUpdatedSubscription = gql`
  subscription LockUpdatedSubscription($ids: [String!]!) {
    modernLockUpdated(where: { id: { in: $ids } }) {
      ...LockFragment
    }
  }

  ${LockFragment}
`;

const CompleteLockRequestMutation = gql`
  mutation CompleteLockRequestMutation(
    $id: String!
    $state: LockRequestState!
  ) {
    completeLockRequest(input: { id: $id, state: $state }) {
      ...LockRequestCompletionFragment
    }
  }

  ${LockRequestCompletionFragment}
`;

const LockRequestCompletedSubscription = gql`
  subscription LockRequestCompletedSubscription($ids: [String!]!) {
    lockRequestCompleted(where: { id: { in: $ids } }) {
      ...LockRequestCompletionFragment
    }
  }

  ${LockRequestCompletionFragment}
`;

/**
 * Batch lock polling and subscriptions.
 */
export const useLockSubscriptions = ({ ids }: { ids: string[] }) => {
  const client = useApolloClient();
  const skip = ids.length === 0;
  const optionsRef = useLiveRef({ skip, ids });
  const emitRequestCompletion = useEmitRequestCompletion();
  const { lockUpdating } = useLockUpdating();
  useRevalidate(() => {
    const { skip, ids } = optionsRef.current;
    if (skip || lockUpdating) return;
    client.query({
      query: LocksQuery,
      variables: { ids },
      fetchPolicy: "network-only",
    });
  });
  useSubscription(LockRequestCompletedSubscription, {
    variables: { ids },
    skip,
    onData: ({ data: { data } }) => {
      if (!data) return;
      emitRequestCompletion(
        data.lockRequestCompleted.lock.id,
        data.lockRequestCompleted,
      );
    },
  });
  useSubscription(LockUpdatedSubscription, {
    skip,
    variables: { ids },
  });
};

export type LockApi = {
  lock: () => Promise<FetchResult>;
  unlock: () => Promise<FetchResult>;
  requestLock: () => Promise<FetchResult>;
  acceptLockRequest: () => void;
  rejectLockRequest: () => void;
  cancelLockRequest: () => void;
  listenRequestCompletion: (listener: RequestCompletionListener) => () => void;
  listenLockForced: (listener: LockForcedListener) => () => void;
  info: LockInfo | null;
};

export type RequestCompletionListener = (
  completion: LockRequestCompletion,
) => void;
export type LockForcedListener = (locker: Locker) => void;

/**
 * Returns info about lock and lock method.
 */
export const useLockApi = (id: string): LockApi => {
  const user = useUser();
  useTrackLockResource(id);

  const completingRequestRef = useRef(false);

  const listenRequestCompletion = useListenRequestCompletion();
  const scopedListenRequestCompletion = useCallback(
    (listener: RequestCompletionListener) =>
      listenRequestCompletion(id, listener),
    [id, listenRequestCompletion],
  );
  const emitRequestCompletion = useEmitRequestCompletion();

  const { data: lockData } = useSafeQuery<LockResult, { id: string }>(
    LockQuery,
    { variables: { id } },
  );
  const lockDataRef = useLiveRef(lockData);
  const { setLockUpdating } = useLockUpdating();

  const lockForcedListeners = useRef<LockForcedListener[]>([]);

  const listenLockForced = useCallback((listener: LockForcedListener) => {
    lockForcedListeners.current.push(listener);
    return () => {
      lockForcedListeners.current = lockForcedListeners.current.filter(
        (x) => x !== listener,
      );
    };
  }, []);

  const previousLockerRef = useRef<Locker | null>(null);
  const locker = lockData?.lock.locker ?? null;

  useEffect(() => {
    if (completingRequestRef.current) return;
    const previousLocker = previousLockerRef.current;
    if (!locker || !previousLocker) return;
    if (locker.user.id === previousLocker.user.id) return;
    if (previousLocker.user.id !== user.id) return;
    lockForcedListeners.current.forEach((listener) => {
      listener(locker);
    });
  }, [user, locker]);

  useEffect(() => {
    previousLockerRef.current = locker;
  }, [locker]);

  const [lock, { loading: lockLoading }] = useMutation(LockMutation, {
    variables: { id },
    optimisticResponse: {
      lock: {
        __typename: "ModernLock",
        id,
        locker: {
          __typename: "ModernLocker",
          appUuid: APP_UUID,
          user: {
            __typename: "User",
            id: user.id,
            firstNameInitials: user.firstNameInitials,
            lastName: user.lastName,
          },
        },
        request: null,
      },
    },
  });
  const [unlock, { loading: unlockLoading }] = useMutation(UnlockMutation, {
    variables: { id },
    optimisticResponse: {
      unlock: {
        __typename: "ModernLock",
        id,
        locker: null,
        request: null,
      },
    },
  });
  const lockUpdating = unlockLoading || lockLoading;
  useEffect(() => {
    setLockUpdating(lockUpdating);
  }, [setLockUpdating, lockUpdating]);
  const [requestLock] = useMutation(RequestLockMutation, {
    variables: { id },
    optimisticResponse: {
      modernRequestLock: {
        __typename: "ModernLock",
        id,
        locker: lockData?.lock.locker ?? null,
        request: {
          __typename: "ModernLockRequest",
          applicant: {
            __typename: "ModernLocker",
            appUuid: APP_UUID,
            user: {
              __typename: "User",
              id: user.id,
              firstNameInitials: user.firstNameInitials,
              lastName: user.lastName,
            },
          },
        },
      },
    },
  });
  const [completeLockRequest] = useMutation(CompleteLockRequestMutation);
  const completeLockRequestWithStatus = useCallback(
    (state: LockRequestCompletion["state"]) => {
      completingRequestRef.current = true;
      const id = lockDataRef.current?.lock.id;
      if (!id) return;
      completeLockRequest({
        variables: { id, state },
      })
        .then(({ data }) => {
          emitRequestCompletion(id, data.completeLockRequest);
        })
        .finally(() => {
          completingRequestRef.current = false;
        });
    },
    [lockDataRef, completeLockRequest, emitRequestCompletion],
  );
  const acceptLockRequest = useCallback(
    () => completeLockRequestWithStatus("accepted"),
    [completeLockRequestWithStatus],
  );
  const rejectLockRequest = useCallback(
    () => completeLockRequestWithStatus("rejected"),
    [completeLockRequestWithStatus],
  );
  const cancelLockRequest = useCallback(
    () => completeLockRequestWithStatus("cancelled"),
    [completeLockRequestWithStatus],
  );
  return {
    lock,
    unlock,
    requestLock,
    acceptLockRequest,
    rejectLockRequest,
    cancelLockRequest,
    listenRequestCompletion: scopedListenRequestCompletion,
    listenLockForced,
    info: lockData?.lock ?? null,
  };
};
