import { useApolloClient } from "@apollo/client";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useField, useFormState } from "react-final-form";
import { useComboboxStore } from "swash/v2/Combobox";
import { RemoteSelect, useRemoteSelectState } from "swash/v2/RemoteSelect";

import { mergeConnections, useSafeQuery } from "@/containers/Apollo";

export const getFormatters = ({ multi, client, fragment, modelName }) => {
  if (multi) {
    return {
      format: (value) =>
        value?.in
          ? value.in
              .map((v) =>
                client.readFragment({
                  id: `${modelName}:${v}`,
                  fragment,
                  fragmentName: fragment.definitions[0].name.value,
                }),
              )
              .filter(Boolean)
          : [],
      parse: (value) =>
        value && value.length ? { in: value.map((v) => v.id) } : null,
    };
  }
  return {
    format: (value) =>
      value
        ? client.readFragment({
            id: `${modelName}:${value.eq}`,
            fragment,
            fragmentName: fragment.definitions[0].name.value,
          })
        : null,
    parse: (value) => (value ? { eq: value.id } : null),
  };
};

export const QueryFilterField = forwardRef(
  (
    {
      name,
      label,
      searchVariables = {},
      query,
      fragment,
      modelName,
      multi,
      labelSelector = (item) => item?.label,
      valueSelector = (item) => item?.id?.toString(),
      iconSelector = () => null,
      disabledSelector = () => false,
      labelElementSelector,
      disabled,
      searchable = false,
      placeholder,
      parseNodes = (data) => data.connection.nodes,
      scale = "md",
      footer,
      ...others
    },
    ref,
  ) => {
    const combobox = useComboboxStore();
    const comboboxValue = combobox.useState("value");
    const client = useApolloClient();

    const emptyValue = useMemo(() => (multi ? [] : null), [multi]);

    const {
      initialValues: { [name]: initialValue },
    } = useFormState({
      subscription: {
        initialValues: true,
      },
    });

    useSafeQuery(query, {
      variables: {
        where: { id: initialValue },
      },
    });

    const search = searchable ? { search: comboboxValue } : {};

    const queryResult = useSafeQuery(query, {
      variables: {
        ...searchVariables,
        where: {
          ...search,
          ...searchVariables.where,
        },
      },
    });

    useImperativeHandle(ref, () => ({ refetch: queryResult.refetch }), [
      queryResult.refetch,
    ]);

    const data = useMemo(() => {
      const tempData = queryResult.data ?? queryResult.previousData;
      if (!tempData)
        return {
          items: [],
          totalCount: 0,
          hasMore: false,
        };
      return {
        items: parseNodes(tempData) ?? [],
        totalCount:
          tempData.connection?.totalCount ?? parseNodes(tempData).length,
        hasMore: tempData.connection?.pageInfo?.hasMore ?? false,
      };
    }, [queryResult.data, queryResult.previousData, parseNodes]);

    const {
      input: { value, onChange },
    } = useField(name, {
      ...getFormatters({ multi, client, fragment, modelName }),
      ...others,
    });

    const state = useRemoteSelectState({
      title: label,
      value: value || emptyValue,
      onChange,
      data,
      combobox,
      searchable,
      fetchMore: (previousData) => {
        queryResult.fetchMore({
          variables: { offset: previousData.items.length },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!previousResult.connection) return previousResult;
            return {
              ...previousResult,
              connection: mergeConnections(
                previousResult.connection,
                fetchMoreResult.connection,
              ),
            };
          },
        });
      },
      getItem: (id) => {
        const value = client.readFragment({
          id: `${modelName}:${id}`,
          fragment,
          fragmentName: fragment.definitions[0].name.value,
        });
        if (!value) {
          throw new Error(`${modelName} (id: ${id}) not found`);
        }
        return value;
      },
      labelSelector,
      iconSelector,
      labelElementSelector,
      disabledSelector: (item) => disabled || disabledSelector(item),
      valueSelector,
      footer,
      loading: queryResult.loading,
    });

    return (
      <div>
        <RemoteSelect
          state={state}
          scale={scale}
          disabled={disabled}
          aria-label={label}
          placeholder={placeholder || label}
          clearLabel="Vider"
        />
      </div>
    );
  },
);
