import clsx from "clsx";

export const Backdrop: React.FC<
  Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "children"
  >
> = (props) => (
  <div
    {...props}
    className={clsx(
      "fixed inset-0 z-backdrop bg-dusk-700 opacity-85",
      props.className,
    )}
  />
);
