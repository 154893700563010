import clsx from "clsx";
import React, { memo, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  IoAlertCircle,
  IoDocumentTextOutline,
  IoLinkOutline,
  IoPencilOutline,
  ZipFile,
} from "swash/Icon";
import { PopoverDisclosure, usePopoverStore } from "swash/Popover";
import { Tooltip } from "swash/Tooltip";

import { DropTargetBorder, InnerDropTarget } from "@/components/DropTarget";
import { fromArticleNodes } from "@/components/rich-editor/convert/fromArticleNodes";
import {
  EditorBlockCapsuleToolbarActions,
  EditorBlockCapsuleToolbarButton,
} from "@/components/teleporters/EditorBlockCapsule";
import { ArticleHoverCard } from "@/containers/article/ArticleHoverCard";
import { ArticleLayoutPricingIndicator } from "@/containers/article/ArticleLayoutPricingIndicator";
import { ArticleLink } from "@/containers/article/ArticleLink";
import { ArticlePublishDateTimeStatus } from "@/containers/article/ArticlePublishDateTimeStatus";
import { ArticlePublishedLink } from "@/containers/article/ArticlePublishedLink";
import { ArticleStatesTooltip } from "@/containers/article/ArticleStatesTooltip";
import { ArticleStateIcon } from "@/containers/article/state/ArticleStateIcon";
import { getArticleStatusColor } from "@/containers/article/util/status";
import { ImageNode } from "@/containers/editor/nodes/ImageNode";
import { SnippetNode } from "@/containers/editor/nodes/SnippetNode";
import { TweetNode } from "@/containers/editor/nodes/TweetNode";
import {
  VideoNode,
  VideoNodeExpanded,
} from "@/containers/editor/nodes/VideoNode";
import {
  InnerSignatures,
  formatSignatures,
} from "@/containers/routes/article/ArticleStatusInfo";

import { ArticleNodeEditor } from "./ArticleNodeEditor";
import { ChangeText } from "./ChangeText";
import {
  EditArticleMetadataPopover,
  checkTitleIsCorrelated,
} from "./EditArticleMetadataPopover";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeGenAIIcon,
  EditorNodeIcon,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";
import { useArticleNodePlugins } from "./Plugins";

// TODO: Type properly
export type Article = any;
export type legacyCustomFields = any;

export type Metadata = {
  title?: string;
  prefix?: string;
  displayMode?: DisplayMode;
};

const EditAction = memo<{
  onUpdate?: (
    value: { displayMode: DisplayMode; title: string | undefined },
    isDirty: boolean,
  ) => void;
  prefix: string;
  metadataTitle?: string;
  articleTitle?: string;
}>(({ onUpdate, prefix, metadataTitle, articleTitle }) => {
  const popover = usePopoverStore();
  return (
    <>
      <PopoverDisclosure
        store={popover}
        render={
          <EditorBlockCapsuleToolbarButton
            title="Éditer le lien"
            icon={IoPencilOutline}
          />
        }
      />
      <EditArticleMetadataPopover
        store={popover}
        onSubmit={onUpdate}
        initialValues={{
          prefix,
          title: metadataTitle ?? (articleTitle || ""),
        }}
        metadataTitle={metadataTitle}
        articleTitle={articleTitle}
      />
    </>
  );
});

const TransformAction = memo<{
  articleTitle?: string;
  metadataTitle?: string;
  onUpdate: (
    value: { displayMode: DisplayMode; title: string | undefined },
    flag: boolean,
  ) => void;
  displayMode: DisplayMode;
}>(({ articleTitle, metadataTitle, onUpdate, displayMode }) => {
  const { title, inverseMode, icon } = (() => {
    switch (displayMode) {
      case "link":
        return {
          title: "Transformer en article intégré",
          inverseMode: "embed" as const,
          icon: IoDocumentTextOutline,
        };
      case "embed":
        return {
          title: "Transformer en lien",
          inverseMode: "link" as const,
          icon: IoLinkOutline,
        };
      default:
        throw new Error(`Unknown displayMode "${displayMode}"`);
    }
  })();
  return (
    <EditorBlockCapsuleToolbarButton
      title={title}
      icon={icon}
      onMouseDown={() => {
        onUpdate(
          { displayMode: inverseMode, title: metadataTitle },
          !checkTitleIsCorrelated(metadataTitle, articleTitle),
        );
      }}
    />
  );
});

const ArticleNodeIcon = memo<{
  article: Article;
  aiRecommended?: boolean;
}>(({ article, aiRecommended }) => {
  return (
    <EditorNodeIcon>
      {aiRecommended && <EditorNodeGenAIIcon />}
      <ArticleLayoutPricingIndicator article={article} />
    </EditorNodeIcon>
  );
});

const ArticleNodeTemplate: React.FC<{
  article: Article;
  displayMode: DisplayMode;
  aiRecommended?: boolean;
  onUpdate?: (
    value: { displayMode: DisplayMode; title: string | undefined },
    isDirty: boolean,
  ) => void;
  tooltip?: React.ReactNode;
  title?: string;
  actions: React.ReactNode;
  prefix: string;
}> = ({
  article,
  displayMode,
  aiRecommended,
  onUpdate,
  tooltip,
  title,
  actions,
  prefix,
}) => {
  const displayModeSelectable =
    (article.layoutEditorialType?.displayModeSelectable ?? true) ||
    // If the article is "embed" we can always switch to "link"
    displayMode === "embed";

  const { pathname } = useLocation();
  const routerArgs = pathname.split("/");
  const currentArticleId = Number(routerArgs[2]);
  const isSelfReferencing =
    routerArgs[1] === "articles" && currentArticleId === article.id;
  const [state] = article.states;

  const hoverCardPosition = useRef<HTMLElement>();

  return (
    <>
      {onUpdate && (
        <EditorBlockCapsuleToolbarActions>
          {displayModeSelectable ? (
            <TransformAction
              articleTitle={article.title}
              onUpdate={onUpdate}
              displayMode={displayMode}
              metadataTitle={title}
            />
          ) : null}
          {actions}
        </EditorBlockCapsuleToolbarActions>
      )}
      <EditorNodeCard
        borderColor={getArticleStatusColor(article)}
        ref={hoverCardPosition}
      >
        <EditorNodeLayout
          style={{ gridTemplateColumns: "fit-content(100%) auto" }}
        >
          <EditorNodeTooltip tooltip={tooltip}>
            <ArticleNodeIcon article={article} aiRecommended={aiRecommended} />
          </EditorNodeTooltip>
          <EditorNodeBody>
            <div className="flex items-center justify-between gap-4">
              <div className="max-w-full grow">
                <ChangeText
                  rawText={title}
                  className="overflow-hidden text-ellipsis	"
                >
                  <span className="font-semibold">{prefix}</span>{" "}
                  <Tooltip
                    tooltip={<ArticleHoverCard article={article} />}
                    positionReference={hoverCardPosition.current}
                    placement="right"
                    nude
                  >
                    <ArticleLink articleId={article.id} target="_blank" inherit>
                      {title}
                    </ArticleLink>
                  </Tooltip>
                </ChangeText>
                <div className="flex items-center gap-2 text-xs">
                  <ArticlePublishDateTimeStatus article={article} />
                  {state && (
                    <ArticleStatesTooltip article={article}>
                      <ArticleStateIcon state={state} className="shrink-0" />
                    </ArticleStatesTooltip>
                  )}
                  <ArticlePublishedLink article={article} scale="xs" iconOnly />
                </div>
              </div>
              {isSelfReferencing && (
                <div className="mr-2">
                  <Tooltip tooltip="L'article ajouté est le même que l'article en cours d'édition">
                    <div>
                      <IoAlertCircle className="cursor-default text-lg text-warning-on" />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </EditorNodeBody>
        </EditorNodeLayout>
      </EditorNodeCard>
    </>
  );
};

const LinkArticleNode: DisplayComponent = ({
  article,
  metadata,
  displayMode,
  aiRecommended,
  onUpdate,
  tooltip,
}) => {
  const prefix = metadata?.prefix || article.layout.articleLinkPrefix;
  return (
    <ArticleNodeTemplate
      article={article}
      displayMode={displayMode}
      onUpdate={onUpdate}
      tooltip={tooltip}
      title={metadata?.title || article.title || "Sans titre"}
      actions={
        <EditAction
          prefix={prefix}
          onUpdate={onUpdate}
          metadataTitle={metadata?.title}
          articleTitle={article.title}
        />
      }
      prefix={prefix}
      aiRecommended={aiRecommended}
    />
  );
};

const EmbedArticleNode: DisplayComponent = ({
  aiRecommended,
  article,
  displayMode,
  displayTitle,
  metadata,
  expanded,
  onUpdate,
  tooltip,
  variant,
}) => {
  const title = metadata?.title || article.title || "Sans titre";
  const prefix = metadata?.prefix || article.layout.articleLinkPrefix;

  if (expanded) {
    return (
      <EmbedArticleNodeExpanded
        article={article}
        displayMode={displayMode}
        displayTitle={displayTitle}
        onUpdate={onUpdate}
        title={title}
        variant={variant}
        actions={
          <EditAction
            prefix={prefix}
            onUpdate={onUpdate}
            metadataTitle={metadata?.title}
            articleTitle={article.title}
          />
        }
      />
    );
  }
  return (
    <ArticleNodeTemplate
      article={article}
      displayMode={displayMode}
      aiRecommended={aiRecommended}
      onUpdate={onUpdate}
      tooltip={tooltip}
      title={title}
      prefix="Article intégré"
      actions={
        <EditAction
          prefix={prefix}
          onUpdate={onUpdate}
          metadataTitle={metadata?.title}
          articleTitle={article.title}
        />
      }
    />
  );
};

const LegacyCustomFieldsHandler: React.FC<{
  legacyCustomFields?: legacyCustomFields;
}> = ({ legacyCustomFields }) => {
  if (!legacyCustomFields) return null;
  if (
    Object.values(legacyCustomFields).every(
      (v) => !v || v === "LegacyCustomFields",
    )
  ) {
    return null;
  }

  return (
    <InnerDropTarget data-filled={true}>
      <DropTargetBorder />
      <div className="space-y-1.5 p-1">
        {legacyCustomFields.backgroundMedia &&
          legacyCustomFields.backgroundMedia.type === "images" && (
            <ImageNode
              metadata={legacyCustomFields.backgroundMedia.metadata}
              articleMediaId={legacyCustomFields.backgroundMedia.id}
              image={legacyCustomFields.backgroundMedia.media}
            />
          )}
        {legacyCustomFields.backgroundMedia &&
          legacyCustomFields.backgroundMedia.type === "videos" && (
            <VideoNode video={legacyCustomFields.backgroundMedia?.media} />
          )}
        {legacyCustomFields.backgroundMedia &&
          legacyCustomFields.backgroundMedia.type === "snippets" && (
            <SnippetNode snippet={legacyCustomFields.backgroundMedia.media} />
          )}
        {legacyCustomFields.video && (
          <VideoNode video={legacyCustomFields.video.media} />
        )}
        {legacyCustomFields.snippet && (
          <SnippetNode snippet={legacyCustomFields.snippet.media} />
        )}
        {legacyCustomFields.multimediaContent && (
          <>
            {legacyCustomFields.multimediaContent.articleMedias.map(
              (articleMedia: any, index: number) => (
                <div key={index} className="flex items-center">
                  <ZipFile />
                  <span className="ml-3">
                    {articleMedia.media.archiveTitle}
                  </span>
                </div>
              ),
            )}
          </>
        )}
        {legacyCustomFields.portfolio && (
          <>
            {legacyCustomFields.portfolio.articleMedias.map(
              (articleMedia: any) => (
                <div key={articleMedia.id}>
                  <ImageNode
                    metadata={articleMedia.metadata}
                    articleMediaId={articleMedia.id}
                    image={articleMedia.media}
                  />
                </div>
              ),
            )}
          </>
        )}
        {legacyCustomFields.aliasUrl && (
          <div>Adresse URL: {legacyCustomFields.aliasUrl}</div>
        )}
        {legacyCustomFields.aliasSource && (
          <div>Source: {legacyCustomFields.aliasSource}</div>
        )}
        {legacyCustomFields.tweet && (
          <TweetNode tweet={legacyCustomFields.tweet} />
        )}
      </div>
    </InnerDropTarget>
  );
};

const EmbedArticleNodeExpanded: React.FC<{
  actions: React.ReactNode;
  article: Article;
  displayMode: DisplayMode;
  displayTitle?: boolean;
  onUpdate?: (
    value: { displayMode: DisplayMode; title: string | undefined },
    isDirty: boolean,
  ) => void;
  title: string;
  variant?: string;
}> = ({
  actions,
  article,
  displayMode,
  displayTitle,
  onUpdate,
  title,
  variant,
}) => {
  const plugins = useArticleNodePlugins();
  const hasTitle = Boolean(article.editorialType.hasTitle && article.title);
  const hasHeading = Boolean(article.editorialType.hasHeading && article.chapo);
  const hasContent = article.editorialType.hasContent;

  const displayModeSelectable =
    article.layoutEditorialType?.displayModeSelectable ?? true;
  return (
    <>
      {onUpdate && (
        <EditorBlockCapsuleToolbarActions>
          {displayModeSelectable ? (
            <TransformAction onUpdate={onUpdate} displayMode={displayMode} />
          ) : null}
          {actions}
        </EditorBlockCapsuleToolbarActions>
      )}
      <EditorNodeCard expanded variant={variant}>
        <EditorNodeLayout>
          <EditorNodeBody multiline>
            {article.layout.type === "video" &&
            variant !== "side-panel" &&
            article.legacyCustomFields.video?.media.playerUrl ? (
              <VideoNodeExpanded
                video={article.legacyCustomFields.video?.media}
              />
            ) : (
              <>
                {hasTitle && displayTitle && (
                  <div className="pb-2 text-xl font-semibold">
                    {title ?? article.title}
                  </div>
                )}
                {hasHeading && (
                  <div
                    className={clsx(
                      "!flex pb-2 text-base",
                      displayTitle ? "" : "font-semibold",
                    )}
                  >
                    {article.chapo}
                  </div>
                )}
                {variant === "side-panel" && (
                  <InnerSignatures
                    className="my-4 !flex text-base !leading-tight"
                    signatures={[
                      ...formatSignatures(article.signatures),
                      ...article.freeAuthors,
                    ]}
                  />
                )}
                {hasContent && (
                  <ArticleNodeEditor
                    contentState={fromArticleNodes(article.nodes, plugins)}
                  />
                )}
                {variant === "side-panel" && (
                  <LegacyCustomFieldsHandler
                    legacyCustomFields={article.legacyCustomFields}
                  />
                )}
              </>
            )}
          </EditorNodeBody>
        </EditorNodeLayout>
      </EditorNodeCard>
    </>
  );
};

type DisplayMode = "embed" | "link";

type DisplayComponentProps = {
  article: Article;
  metadata?: Metadata;
  variant?: string;
  displayTitle?: boolean;
  onUpdate?: (
    value: { displayMode: DisplayMode; title: string | undefined },
    isDirty: boolean,
  ) => void;
  displayMode: DisplayMode;
  aiRecommended?: boolean;
  expanded?: boolean;
  tooltip?: React.ReactNode;
};

type DisplayComponent = React.FC<DisplayComponentProps>;

const displayComponents: Record<DisplayMode, DisplayComponent> = {
  embed: EmbedArticleNode,
  link: LinkArticleNode,
};

export const ArticleNode: React.FC<{
  article: Article;
  metadata?: Metadata;
  variant?: string;
  displayTitle?: boolean;
  onUpdate?: (
    value: { displayMode: DisplayMode; title: string | undefined },
    isDirty: boolean,
  ) => void;
  tooltip?: React.ReactNode;
  expanded?: boolean;
  aiRecommended?: boolean;
}> = ({
  article,
  metadata,
  variant,
  displayTitle = true,
  onUpdate,
  tooltip,
  expanded,
  aiRecommended,
}) => {
  const defaultDisplayMode: DisplayMode =
    article.layoutEditorialType?.displayMode ?? "link";
  const displayMode = metadata?.displayMode ?? defaultDisplayMode;
  const DisplayComponent = displayComponents[displayMode];
  return (
    <DisplayComponent
      article={article}
      variant={variant}
      metadata={metadata}
      displayTitle={displayTitle}
      onUpdate={onUpdate}
      displayMode={displayMode}
      aiRecommended={aiRecommended}
      expanded={expanded && displayMode === "embed"}
      tooltip={tooltip}
    />
  );
};
