import { DocumentNode, gql, useSubscription } from "@apollo/client";
import { useCallback } from "react";

import * as MetaPanel from "@/containers/article/ArticleMetaPanel";
import { List } from "@/containers/list/List";
import { ListStateProps, useListState } from "@/containers/list/ListState";

type Node = {
  id: number;
  deleted?: boolean;
};

export type ArticleListProps<TNode = Node> = {
  nodes: TNode[];
  cells: ListStateProps<TNode>["cells"];
  interactions?: ListStateProps<TNode>["interactions"];
  provider?: ListStateProps<TNode>["provider"];
  grouping?: ListStateProps<TNode>["grouping"];
  subGrouping?: ListStateProps<TNode>["subGrouping"];
  defaultSubGroups?: ListStateProps<TNode>["defaultSubGroups"];
  orderBy?: ListStateProps<TNode>["orderBy"];
  orderDirection?: ListStateProps<TNode>["orderDirection"];
  hasMore: ListStateProps<TNode>["hasMore"];
  loadMore: () => void;
  totalCount: number;
  loading: boolean;
  subscription: DocumentNode;
  readOnly?: boolean;
  portal?: boolean;
  showEmptyGroups?: boolean;
};

export const ArticleList = <TNode extends Node>(
  props: ArticleListProps<TNode>,
) => {
  const checkMetaPanelActivated = MetaPanel.useCheckIsActivated();
  const activateMetaPanel = MetaPanel.useActivate();
  const activeSelector = useCallback(
    (node: Node) => checkMetaPanelActivated(node.id),
    [checkMetaPanelActivated],
  );
  const list = useListState({
    nodes: props.nodes,
    cells: props.cells,
    interactions: props.interactions,
    provider: props.provider,
    grouping: props.grouping,
    subGrouping: props.subGrouping,
    defaultSubGroups: props.defaultSubGroups,
    orderBy: props.orderBy,
    orderDirection: props.orderDirection,
    hasMore: props.hasMore,
    activeSelector,
    hiddenSelector: (node) => Boolean(node.deleted),
    onActiveChange: (node) => {
      if (!props.readOnly) {
        activateMetaPanel(node.id);
      }
    },
    readOnly: props.readOnly,
    portal: props.portal,
    showEmptyGroups: props.showEmptyGroups,
  });

  const ids = props.nodes.map((node) => node.id);

  useSubscription(props.subscription, {
    variables: { ids },
    skip: ids.length === 0 || !props.subscription,
  });

  return (
    <List
      loadMore={props.loadMore}
      loading={props.loading}
      state={list}
      totalCount={props.totalCount}
    />
  );
};

ArticleList.fragments = {
  article: gql`
    fragment ArticleList_article on Article {
      id
      deleted
    }
  `,
};
